
@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Light.woff2') format('woff2'),
        url('../fonts/FuturaPT-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Book.woff2') format('woff2'),
        url('../fonts/FuturaPT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Futura PT Demi';
    src: url('../fonts/FuturaPT-Demi.woff2') format('woff2'),
        url('../fonts/FuturaPT-Demi.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Medium.woff2') format('woff2'),
        url('../fonts/FuturaPT-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Bold.woff2') format('woff2'),
        url('../fonts/FuturaPT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'Futura PT Extra';
    src: url('../fonts/FuturaPT-ExtraBold.woff2') format('woff2'),
        url('../fonts/FuturaPT-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../fonts/FuturaPT-Heavy.woff2') format('woff2'),
        url('../fonts/FuturaPT-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
