.site-header {
    position: absolute;
    z-index: 100;
    left: 0;
    width: 100%;
    transition-duration: .2s;
    background: #FCF036;

    .container {
        padding: 0 vw(19) 0 0;
    }

    &.fixed {
        position: fixed;
        top: 0;
    }
}

.site-header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: vw(71);

    &__link {
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        font-size: vw(18);
        line-height: vw(24);

        &--bold {
            @include demi();
        }

        &:hover {
            color: rgb(81, 78, 0);
        }

        &.active {
            display: none;
        }

        &:focus {}
    }
}

.place-for-header {
    height: vw(71);
}



@media screen and (max-width:1000px) {
    .site-header, .place-for-header{
        display: none;
    }
}
