.a4-products{
  margin-top: vw(91);
  @include media((
          margin-top: (sm: 50px),
  ));
}

#querstion{
  margin-top: vw(60);
  position: relative;
  @include media((
          padding-top: (md: 37px),
          margin-top: (md: 40px),
  ));
  #testover{
    z-index: 20;
    background: rgba(255, 255, 255, 0.51);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
  }
  .variants{
    display: flex;
    margin-top: vw(40);
    justify-content: space-between;
    @include media((
            margin-top: (sm: 25px),
            display: (sm: block),
    ));
    .vaiant{
      width: 30%;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
      @include media((
              width: (sm: 100%),
              display: (sm: flex),
              align-items: (sm: center),
              padding: (sm: 10px),
              text-align: (sm: left),
      ));
      +.vaiant{
        @include media((
                margin-top: (sm:  25px),
        ));
      }
      .img-block{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: vw(340);
        z-index: 1;
        @include media((
                height: (sm: 80px),
                width: (sm: 116px),
                flex-shrink: (sm: 0),
                margin-right: (sm: 30px),
        ));
        img{
          max-width: 100%;
          max-height: 100%;
          display: block;
          z-index: 3;
          position: relative;
          transition: 0.3s ease;
          top: 0;
        }
        &::before,
        &::after{
          content: "";
          pointer-events: none;
          position: absolute;
          width: vw(146);
          height: vw(55);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          z-index: -1;
          transition: 0.3s ease;
          opacity: 0;
          margin: 0;
          @include media((
                  display: (sm: none),
          ));
        }
        &::before{
          background-image: url(../img/blue.png);
        }
        &::after{
          background-image: url(../img/green.png);
        }
      }
      .text{
        font-size: vw(18);
        line-height: vw(26);
        margin-top: vw(40);
        position: relative;
        transition: 0.3s ease;
        transform: scale(1);
        @include media((
                margin-top: (sm:  0),
                font-size: (sm:  14px),
                line-height: (sm:  20px),
        ));
        &::before{
          top: vw(-16);
          left: 50%;
          margin-left: vw(-5.625);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          position: absolute;
          display: block;
          width: vw(11.25);
          height: vw(13);
          content: '';
          background: url(../img/triangle.svg) no-repeat center center;
          background-size: contain;
          @include media((
                  display: (sm:  none),
          ));
        }
      }



      &:hover{
        .img-block{
          img{
            top: vw(-25);
            @include media((
                    top: (sm: 0),
            ));
          }
          &::before,
          &::after{
            opacity: 1;
            @include media((
                    opacity: (sm: 0),
            ));
          }
        }
        .text{
          transform: scale(1.1);
          @include media((
                  transform: (sm:  scale(1)),
          ));
        }
      }

      &.variant-1{
        .img-block{
          &::before{
            transform: rotate(-62deg);
            bottom: 30%;
          }
          &::after{
            transform: rotate(44deg);
            bottom: 10%;
          }
        }
        &:hover{
          .img-block{
            &::before{
              bottom: 60%;
              margin-left: vw(70);
            }
            &::after{
              bottom: 40%;
              margin-left: vw(-70);
            }
          }
        }
      }

      &.variant-2{
        .img-block{
          &::before{
            transform: rotate(60deg);
            bottom: 30%;
          }
          &::after{
            transform: rotate(-57deg);
            bottom: 30%;
          }
        }
        &:hover{
          .img-block{
            &::before{
              bottom: 60%;
              margin-left: vw(-120);
            }
            &::after{
              bottom: 50%;
              margin-left: vw(90);
            }
          }
        }
      }


      &.variant-3{
        .img-block{
          &::before{
            transform: rotate(-62deg);
            bottom: 20%;
          }
          &::after{
            transform: rotate(44deg);
            bottom: 30%;
          }
        }
        &:hover{
          .img-block{
            &::before{
              bottom: 50%;
              margin-left: vw(70);
            }
            &::after{
              bottom: 60%;
              margin-left: vw(-70);
            }
          }
        }
      }

    }
  }
}
#quest-count{
  position: absolute;
  top: vw(-4);
  left: vw(-51);
  display: block;
  width: vw(38);
  height: vw(38);
  text-align: center;
  color: #000000;
  border-radius: 50%;
  background: #FCF036;
  font-size: vw(20);
  line-height: vw(38);
  @include demi();
  @include media((
          width: (md: 30px),
          height: (md: 30px),
          font-size: (md: 14px),
          line-height: (md: 30px),
          top: (md: 0px),
          left: (md: 0px),
  ));
}

#otvet{
  display: none;
  margin-top: vw(60);
  @include media((
          margin-top: (sm:  30px),
  ));
  .img{
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    @include media((
            margin-bottom: (sm:  15px),
    ));
    img{
      display: block;
      width: 100%;
    }
    .title{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      span{
        display: block;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        padding: 9px;
        background: #FCF036;
        @include media((
                padding: (sm:  8px),
                font-size: (sm:  14px),
        ));
      }
    }
  }
  .share-row{
    display: flex;
    align-items: center;
    margin-top: 40px;
    @include media((
            display: (sm:  block),
            margin-top: (sm:  20px),
    ));
    .share{
      display: flex;
      align-items: center;
      @include media((
              flex-direction: (sm:  column),
      ));
      .text{
        font-size: 16px;
        color: #000000;
        @include media((
                font-size: (sm:  12px),
        ));
      }
      .social-block{
        margin-left: 10px;
        display: flex;
        align-items: center;
        @include media((
                margin-left: (sm:  0px),
                margin-top: (sm:  15px),
        ));
        .soc-link{
          border: none;
          cursor: pointer;
          border-radius: 100%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          @include media((
                  width: (sm:  35px),
                  height: (sm:  35px),
                  margin-left: (sm:  7px),
                  margin-right: (sm:  7px),
          ));
          &.vk{
            background: #5181B8;
            &:hover{
              background: #39587e;
            }
          }
          &.fb{
            background:#3B5998;
            &:hover{
              background: #223056;
            }
          }
          &.tw{
            background: #41ABE1;
            &:hover{
              background: #2d7da5;
            }
          }
          svg{
            width: 20px;
            height: 20px;
            @include media((
                    width: (sm:  17px),
                    height: (sm:  17px),
            ));
            *{
              fill: #FFFFFF;
            }
          }
        }
      }
    }
    .again{
      display: flex;
      align-items: center;
      margin-left: 80px;
      @include media((
              margin-left: (sm:  0),
              margin-top: (sm:  20px),
              flex-direction: (sm:  column),
      ));
      .text{
        font-size: 16px;
        color: #000000;
        margin-right: 20px;
        @include media((
                font-size: (sm:  12px),
                margin-right: (sm:  0),
        ));
      }
      .again-link{
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: #FCF036 url("../img/again.svg") 10px 10px no-repeat;
        transition: 0.3s ease;
        @include media((
                width: (sm:  35px),
                height: (sm:  35px),
                background-position: (sm:  9px 9px),
                background-size: (sm:  20px auto),
                margin-top: (sm:  15px),
        ));
        &:hover{
          transform: rotate(180deg);
        }
      }
    }
  }
}

