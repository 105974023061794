.a2-products {
  margin-top: vw(91);
  counter-reset: products;
  @include media((
          margin-top: (sm: 50px),
  ));
  .product{
    margin-top: vw(67);
    .product__bottom{
      margin-top: 90px;
      @include media((
              margin-top: (sm:10px),
      ));
      .prod-info{
        width: 100%;
        left: 0;
        top: -50px;
        &::before{
          transform: rotate(270deg);
          left: 322px;
          top: 35px;
        }
      }
    }
  }
}



.product-a2-1 {
  .product__decoration {
    &--blue {
      left: 478.52px;
      top: 210px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-53deg);
      @include media((
              left: (sm:200px),
              top: (sm:88px),
      ));
    }
    &--green {
      left: 96px;
      top: 50px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(19deg) scaleX(-1) scaleY(-1);
      @include media((
              left: (sm:40px),
              top: (sm:21px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: vw(325);
      margin: 0 auto;
      @include media((
              width: (sm:136px),
      ));
    }

  }
}

.product-a2-2 {
  .product__decoration {
    &--blue {
      left: 163.52px;
      top: 110px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-37deg) scaleX(-1) scaleY(-1);
      @include media((
              left: (sm:68px),
              top: (sm:46px),
      ));
    }
    &--green {
      left: 378px;
      top: 10px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(208deg) scaleX(-1) scaleY(-1);
      @include media((
              left: (sm:159px),
              top: (sm:4px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: vw(98);
      margin: 0 auto;
      @include media((
              width: (sm:41px),
      ));
    }
  }
}

.product-a2-3 {
  .product__decoration {
    &--blue {
      left: 106.52px;
      top: 24px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-161deg);
      @include media((
              left: (sm:44px),
              top: (sm:10px),
      ));
      &-2{
        left: 184.52px;
        top: 204px;
        transform: rotateX(0deg) rotateY(0deg) rotateZ(-48deg);
        @include media((
                left: (sm:77px),
                top: (sm:85px),
        ));
      }
    }
    &--green {
      left: 454.52px;
      top: 164px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-159deg) scaleX(-1);
      @include media((
              left: (sm:190px),
              top: (sm:68px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: vw(328);
      margin: 0 auto;
      @include media((
              width: (sm:138px),
      ));
    }
  }
}


.product-a2-4 {
  .product__decoration {
    &--blue {
      left: 423.52px;
      top: 30px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-29deg) scaleY(-1);
      @include media((
              left: (sm:177px),
              top: (sm:12px),
      ));
    }
    &--green {
      left: 178px;
      top: 242px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-154deg) scaleX(-1);
      @include media((
              left: (sm:74px),
              top: (sm:60px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: vw(131);
      margin: 0 auto;
      @include media((
              width: (sm:55px),
      ));
    }
  }
}

.product-a2-5 {
  .product__decoration {
    &--blue {
      left: 438.52px;
      top: 36px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(34deg) scaleY(-1);
      @include media((
              left: (sm:184px),
              top: (sm:15px),
      ));
    }
    &--green {
      left: 104px;
      top: 44px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-125deg);
      @include media((
              left: (sm:43px),
              top: (sm:18px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: vw(291);
      margin: 0 auto;
      @include media((
              width: (sm:122px),
      ));
    }
  }
}

.product-a2-6 {
  .product__decoration {
    &--blue {
      left: 374.52px;
      top: 39px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-39deg) scaleY(-1);
      @include media((
              left: (sm:157px),
              top: (sm:16px),
      ));
    }
    &--green {
      left: 182px;
      top: 274px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(4deg) scaleX(-1) scaleY(-1);
      @include media((
              left: (sm:76px),
              top: (sm:115px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: vw(76);
      margin: 0 auto;
      @include media((
              width: (sm:32px),
      ));
    }
  }
}

.product-a2-7 {
  .product__decoration {
    &--blue {
      left: 134.52px;
      top: 238px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(22deg) scaleY(-1);
      @include media((
              left: (sm:56px),
              top: (sm:100px),
      ));
    }
    &--green {
      left: 422px;
      top: 114px;
      z-index: -1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(156deg) scaleX(-1) scaleY(-1);
      @include media((
              left: (sm:177px),
              top: (sm:48px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: vw(208);
      margin: 0 auto;
      @include media((
              width: (sm:87px),
      ));
    }
  }
}

.product-a2-8 {
  .product__decoration {
    &--blue {
      left: 394.52px;
      top: 145px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(22deg) scaleY(-1);
      @include media((
              left: (sm:165px),
              top: (sm:60px),
      ));
    }
    &--green {
      left: 130px;
      top: 28px;
      z-index: -1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(156deg) scaleX(-1) scaleY(-1);
      @include media((
              left: (sm:54px),
              top: (sm:11px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: vw(127);
      margin: 0 auto;
      @include media((
              width: (sm:53px),
      ));
    }
  }
}


.product-a2-9{
  .product__decoration {
    &--blue {
      left: 120.52px;
      top: 225px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(30deg) scaleY(-1);
      @include media((
              left: (sm:50px),
              top: (sm:94px),
      ));
    }
    &--green {
      left: 430px;
      top: 27px;
      z-index: -1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-206deg) scaleX(-1);
      @include media((
              left: (sm:180px),
              top: (sm:11px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: vw(207);
      margin: 0 auto;
      @include media((
              width: (sm:87px),
      ));
    }
  }
}

.product-a2-10{
  .product__decoration {
    &--blue {
      left: 210.52px;
      top: 254px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(30deg) scaleY(-1);
      @include media((
              left: (sm:88px),
              top: (sm:68px),
      ));
    }
    &--green {
      left: 470px;
      top: 27px;
      z-index: -1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-206deg) scaleX(-1);
      @include media((
              left: (sm:197px),
              top: (sm:11px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: vw(306);
      margin: 0 auto;
      @include media((
              width: (sm:128px),
      ));
    }
  }
}