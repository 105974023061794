.a3-products {
  margin-top: vw(91);
  counter-reset: products;
  @include media((
          margin-top: (sm: 50px),
  ));
  .product{
    margin-top: vw(67);
    .product__bottom{
      margin-top: 10px;
    }
  }
}

.product-a3-1 {
  .product__decoration {
    &--blue {
      top: 61px;
      left: 331px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-21deg);
      z-index: 1;
      @include media((
              top: (sm: 25px),
              left: (sm: 169px),
      ));
    }
    &--green {
      top: 47px;
      right: 502px;
      z-index: 1;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(19deg) scaleX(-1) scaleY(-1);
      @include media((
              top: (sm: 19px),
              right: (sm: 171px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: 237px;
      margin-left: 170px;
      padding-top: 17px;
      @include media((
              width: (sm: 99px),
              margin-left: (sm: 96px),
              padding-top: (sm: 7px),
      ));
    }
  }
  .prod-info {
    &--1 {
      top: 161px;
      left: 21px;
      &::before {
        top: 20px;
        right: 1px;
        transform: rotate(180deg);
      }
    }
  }
}

.product-a3-2 {
  .product__decoration {
    &--blue {
      top: 154px;
      left: 81px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-37deg) scaleX(-1) scaleY(-1);
      z-index: 1;
      @include media((
              top: (sm: 64px),
              left: (sm: 64px),
      ));
    }
    &--green {
      top: 27px;
      right: 135px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(208deg) scaleX(-1) scaleY(-1);
      z-index: 1;
      @include media((
              top: (sm: 11px),
              right: (sm: 26px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: 359px;
      margin-left: 84px;
      padding-top: 42px;
      @include media((
              width: (sm: 150px),
              margin-left: (sm: 65px),
              padding-top: (sm: 17px),
      ));
    }
  }
  .prod-info {
    &--1 {
      top: 8px;
      left: 20px;
      width: 145px;
      &::before {
        top: 13px;
        right: -23px;
        transform: rotate(180deg);
      }
    }
  }
}

.product-a3-3 {
  .product__decoration {
    &--green {
      top: 226px;
      right: 470px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-159deg) scaleX(-1);
      z-index: 1;
      @include media((
              top: (sm: 95px),
              right: (sm: 167px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: 243px;
      margin-left: 144px;
      padding-top: 17px;
      @include media((
              width: (sm: 102px),
              margin-left: (sm: 90px),
              padding-top: (sm: 7px),
      ));
    }
  }
  .prod-info {
    &--1 {
      top: 208px;
      left: 396px;
      &::before {
        top: 14px;
        left: -37px;
      }
    }
  }
}


.product-a3-4 {
  .product__decoration {
    &--blue {
      top: 237px;
      left: 367px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-29deg) scaleY(-1);
      z-index: 1;
      @include media((
              top: (sm: 99px),
              left: (sm: 174px),
      ));
    }
    &--green {
      top: 225px;
      right: 495px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-154deg) scaleX(-1);
      z-index: 1;
      @include media((
              top: (sm: 94px),
              right: (sm: 188px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: 314px;
      margin-left: 107px;
      padding-top: 26px;
      @include media((
              width: (sm: 132px),
              margin-left: (sm: 65px),
              padding: (sm: 11px),
      ));
    }
  }
  .prod-info {
    &--1 {
      top: 28px;
      left: 363px;
      width: 180px;
      &::before {
        top: 10px;
        left: -32px;
        transform: rotate(270deg);
      }
    }
  }
}


.product-a3-5 {
  .product__decoration {
    &--blue {
      top: 136px;
      left: 221px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(34deg) scaleY(-1);
      z-index: 1;
      @include media((
              top: (sm: 57px),
              left: (sm: 113px),
      ));
    }
    &--green {
      top: 267px;
      right: 149px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-40deg);
      z-index: 1;
      @include media((
              top: (sm: 112px),
              right: (sm: 42px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: 212px;
      position: absolute;
      left: 292px;
      top: 46px;
      @include media((
              width: (sm: 89px),
              left: (sm: 142px),
              top: (sm: 19px),
      ));
    }
    &--2 {
      width: 155px;
      margin-left: 39px;
      padding-top: 91px;
      @include media((
              width: (sm: 65px),
              margin-left: (sm: 36px),
              padding-top: (sm: 38px),
      ));
    }
  }
  .prod-info {
    &--1 {
      top: 26px;
      left: 18px;
      width: 337px;
      &::before {
        top: 4px;
        right: -17px;
        transform: rotate(270deg);
      }
    }
    &--2 {
      top: 258px;
      left: 242px;
      &::before {
        top: 11px;
        left: -34px;
      }
    }
  }
}


.product-a3-6 {
  .product__decoration {
    &--blue {
      top: 81px;
      left: 303px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-39deg) scaleY(-1);
      z-index: 1;
      @include media((
              top: (sm: 34px),
              left: (sm: 147px),
      ));
    }
    &--green {
      top: 366px;
      left: 112px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(4deg) scaleX(-1) scaleY(-1);
      z-index: 1;
      @include media((
              top: (sm: 153px),
              left: (sm: 67px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: 195px;
      position: absolute;
      left: 340px;
      top: 80px;
      @include media((
              width: (sm: 82px),
              left: (sm: 162px),
              top: (sm: 33px),
      ));
    }
    &--2 {
      width: 247px;
      margin-left: 39px;
      padding-top: 102px;
      padding-bottom: 70px;
      @include media((
              width: (sm: 103px),
              margin-left: (sm: 36px),
              padding-top: (sm: 42px),
              padding-bottom: (sm: 29px),
      ));
    }
  }
  .prod-info {
    &--1 {
      top: 24px;
      left: 16px;
      width: 435px;
      &::before {
        top: 4px;
        right: -15px;
        transform: rotate(270deg);
      }
    }
    &--2 {
      top: 306px;
      left: 367px;
      &::before {
        top: 23px;
        left: -43px;
      }
    }
  }
}


.product-a3-7 {
  .product__decoration {
    &--blue {
      top: 164px;
      left: 61px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(22deg) scaleY(-1);
      z-index: 1;
      @include media((
              top: (sm: 69px),
              left: (sm: 55px),
      ));
    }
    &--green {
      top: 14px;
      right: 169px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(156deg) scaleX(-1) scaleY(-1);
      z-index: 1;
      @include media((
              top: (sm: 5px),
              right: (sm: 41px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: 90px;
      margin-left: 221px;
      padding-top: 26px;
      @include media((
              width: (sm: 37px),
              margin-left: (sm: 123px),
              padding-top: (sm: 11px),
      ));
    }
  }
  .prod-info {
    &--1 {
      top: 114px;
      left: 396px;
      width: 140px;
      &::before {
        top: 14px;
        left: -35px;
      }
    }
  }
}



.product-a3-8 {
  .product__decoration {
    &--blue {
      top: 49px;
      left: 371px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(22deg) scaleY(-1);
      z-index: 1;
      @include media((
              top: (sm: 20px),
              left: (sm: 186px),
      ));
    }
    &--green {
      top: 257px;
      left: 86px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(156deg) scaleX(-1) scaleY(-1);
      z-index: 1;
      @include media((
              top: (sm: 108px),
              left: (sm: 66px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: 204px;
      margin-left: 144px;
      padding-top: 24px;
      @include media((
              width: (sm: 85px),
              margin-left: (sm: 90px),
              padding-top: (sm: 10px),
      ));
    }
  }
  .prod-info {
    &--1 {
      top: 217px;
      left: 407px;
      width: 130px;
      &::before {
        top: 14px;
        left: -34px;
      }
    }
  }
}



.product-a3-9 {
  .product__decoration {
    &--green {
      top: 110px;
      right: 89px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-232deg) scaleX(-1);
      z-index: 1;
      @include media((
              top: (sm: 46px),
              right: (sm: 7px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: 427px;
      margin-left: 54px;
      padding-top: 42px;
      @include media((
              width: (sm: 179px),
              margin-left: (sm: 52px),
              padding-top: (sm: 17px),
      ));
    }
  }
  .prod-info {
    &--1 {
      top: 11px;
      left: 16px;
      width: 270px;
      &::before {
        top: 2px;
        right: -15px;
        transform: rotate(270deg);
      }
    }
  }
}



.product-a3-10 {
  .product__decoration {
    &--blue {
      top: 217px;
      left: 41px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(30deg) scaleY(-1);
      z-index: 1;
      @include media((
              top: (sm: 91px),
              left: (sm: 17px),
      ));
    }
    &--green {
      top: 47px;
      right: 115px;
      transform: rotateX(0deg) rotateY(0deg) rotateZ(-206deg) scaleX(-1);
      z-index: 1;
      @include media((
              top: (sm: 19px),
              right: (sm: 48px),
      ));
    }
  }
  .product__prod-pic {
    &--1 {
      width: 224px;
      margin-left: 215px;
      padding-top: 6px;
      @include media((
              width: (sm: 94px),
              margin-left: (sm: 90px),
              padding-top: (sm: 2px),
      ));
    }
  }
  .prod-info {
    &--1 {
      top: 64px;
      left: 17px;
      width: 168px;
      &::before {
        top: 14px;
        right: -17px;
        transform: rotate(180deg);
      }
    }
  }
}