$font1: "Futura PT",
sans-serif;
$resolution: 1600;

a {
	text-decoration: none;
}

html,
body {}

body {
	color: #000000;
	font-family: $font1;
	font-size: 16px;
	margin: 0;
	background: #ffffff;
}


#jGrowl {
	z-index: 999999;
}

*:focus {
	outline: none;
}

.hide {
	display: none;
}

img {
	max-width: 100%;
	height: auto;
}

.container {
	display: flex;
	width: vw(1065);
	margin: 0 auto;
	box-sizing: border-box;

	&__left {
		width: vw(666);
		margin-right: vw(99);
	}

	&__right {
		width: vw(300);
		border-radius: 0 0 40px 40px;
	}
}

.tab-content{
	display: none;
	&.active{
		display: block;
	}
}

.emoji{
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url("../img/smile.png") center center no-repeat;
	background-size: 100% 100%;
	@include media((
					width: (sm: 15px),
					height: (sm: 15px),
	));
}

@media screen and (max-width:1215px){
	.container{
		width: calc(100% - 60px);
	}
	.container__right{
		width: 250px;
	}
	.container__left {
		margin-right: auto;
		padding-left: 32px;
		box-sizing: border-box;
	}
}

@media screen and (max-width:1000px){
	.container{
		width: calc(100% - 40px);
		flex-direction: column;
	}
	.container__right{
		width: 100%;
		display: none;
	}
	.container__left {
		width: 100%;
		margin-right: 0;
		padding-left: 0;
	}
}
