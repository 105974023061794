.section-read-also {
    margin-top: 102px;
    width: 100%;
    @include media((
            margin-top: (sm: 45px),
    ));
    &__title {
        text-align: center;
        width: 100%;
        @include demi();
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;

        color: #000000;
        @include media((
                font-size: (sm: 14px),
                line-height: (sm: 20px),
        ));
    }

}

.read-also-container {
    display: flex;
    justify-content: space-between;
    margin-top: 38px;
    @include media((
            flex-direction: (700: column),
            align-items: (700: center),
            margin-top: (700: 10px),
    ));
}

.read-also-item {
    width: 30%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    @include media((
            width: (700: 100%),
            max-width: (700: 300px),
            margin-bottom: (700: 30px),
    ));
    &__pic {
        width: 298px;
        max-width: 100%;
        height: 264px;
        transition: 0.3s ease;
        img {
            width: 100%;
            height: 100%;
            object-position: center center;
            object-fit: contain;
        }
    }

    &__title {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;

        color: #000000;
        width: 100%;
        min-height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        @include media((
                font-size: (sm: 14px),
                line-height: (sm: 20px),
                min-height: (sm: 0),
                margin-top: (sm: 0),
        ));
    }

    &__link {
        width: 170px;
        height: 40px;
        transition: 0.3s ease;
        background: #FCF036;
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media((
                width: (sm: 150px),
                height: (sm: 35px),
                font-size: (sm: 14px),
        ));

        &:active {}

        &:focus {}
    }

    img {}

    &.active{
        display: none;
    }

    &:hover {
        .read-also-item__pic{
            transform: scale(1.1);
        }
        .read-also-item__link{
            background: #bbaf2a;
        }
    }
}


.copy{
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    padding: 17px 0;
    margin-top: 62px;
    @include media((
            margin-top: (sm: 15px),
            font-size: (sm: 12px),
            padding: (sm: 0),
    ));
}